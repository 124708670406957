import { QueryCache, QueryClient } from '@tanstack/react-query';
const queryCache = new QueryCache();
export const queryClient = new QueryClient({
    queryCache,
    defaultOptions: {
        queries: {
            staleTime: 30000,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            networkMode: 'online',
        },
        mutations: {
            networkMode: 'always',
        },
    },
});
