import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactHookForm from 'react-hook-form';
import * as ReactI18next from 'react-i18next';
import * as ReactRedux from 'react-redux';
import * as ReactRouterDOM from 'react-router-dom';
import * as ReactTransitionGroup from 'react-transition-group';
import * as BifrostKeycloakClient from '@bifrost/keycloak-client';
import * as BifrostProtoCloud from '@bifrost/proto-cloud';
import * as BifrostWebComponents from '@bifrost/web-components';
import * as BifrostWebTemplates from '@bifrost/web-templates';
import * as Connect from '@connectrpc/connect';
import * as ConnectWeb from '@connectrpc/connect-web';
import * as DNDCore from '@dnd-kit/core';
import * as DNDModifiers from '@dnd-kit/modifiers';
import * as DNDSortable from '@dnd-kit/sortable';
import * as ReduxToolkit from '@reduxjs/toolkit';
import * as DayJS from 'dayjs';
import * as I18next from 'i18next';
import * as Lodash from 'lodash';
import { registerApplication, start } from 'single-spa';
import { createModuleDefinition } from '@utils/create_module_definition';
import 'systemjs';
import 'import-map-overrides';
import './index.styles.scss';
BifrostKeycloakClient.createClient({
    url: window.bifrostWeb.keycloak.url,
    realm: window.bifrostWeb.keycloak.realm,
    clientId: window.bifrostWeb.keycloak.clientId,
});
System.set('dependencies:react', React);
System.set('dependencies:react-dom', ReactDOM);
System.set('dependencies:react-redux', ReactRedux);
System.set('dependencies:react-router-dom', ReactRouterDOM);
System.set('dependencies:react-hook-form', ReactHookForm);
System.set('dependencies:react-transition-group', ReactTransitionGroup);
System.set('dependencies:@reduxjs/toolkit', ReduxToolkit);
System.set('dependencies:dayjs', DayJS);
System.set('dependencies:@dnd-kit/core', DNDCore);
System.set('dependencies:@dnd-kit/modifiers', DNDModifiers);
System.set('dependencies:@dnd-kit/sortable', DNDSortable);
System.set('dependencies:lodash', Lodash);
System.set('dependencies:@connectrpc/connect', Connect);
System.set('dependencies:@connectrpc/connect-web', ConnectWeb);
System.set('dependencies:@bifrost/web-components', BifrostWebComponents);
System.set('dependencies:@bifrost/web-templates', BifrostWebTemplates);
System.set('dependencies:@bifrost/proto-cloud', BifrostProtoCloud);
System.set('dependencies:@bifrost/keycloak-client', BifrostKeycloakClient);
System.set('dependencies:i18next', I18next);
System.set('dependencies:react-i18next', ReactI18next);
window.bifrostWeb = {
    ...window.bifrostWeb,
    capabilities: ['scanDeviceQRCode', 'connectToDevice', 'getWiFiNetworks', 'configureDevice', 'finishOnboarding'],
    navigate: path => window.history.pushState({}, '', path),
    getIsMobile: () => !!window?.bifrostNative,
};
const commonModuleProps = {
    landscape: window.bifrostWeb.landscape,
    backend: window.bifrostWeb.backend,
};
const sharedModuleDefinition = createModuleDefinition('shared');
registerApplication({
    name: sharedModuleDefinition.name,
    app: () => System.import(sharedModuleDefinition.name),
    activeWhen: () => true,
    customProps: {
        ...commonModuleProps,
        modulePath: sharedModuleDefinition.url,
        appId: 'bif',
    },
});
const playModuleDefinition = createModuleDefinition('play');
registerApplication({
    name: playModuleDefinition.name,
    app: () => System.import(playModuleDefinition.name),
    activeWhen: playModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: playModuleDefinition.url,
    },
});
const devicesModuleDefinition = createModuleDefinition('devices');
registerApplication({
    name: devicesModuleDefinition.name,
    app: () => System.import(devicesModuleDefinition.name),
    activeWhen: devicesModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: devicesModuleDefinition.url,
    },
});
const servicesModuleDefinition = createModuleDefinition('services');
registerApplication({
    name: servicesModuleDefinition.name,
    app: () => System.import(servicesModuleDefinition.name),
    activeWhen: servicesModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: servicesModuleDefinition.url,
    },
});
const profileModuleDefinition = createModuleDefinition('profile');
registerApplication({
    name: profileModuleDefinition.name,
    app: () => System.import(profileModuleDefinition.name),
    activeWhen: profileModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: profileModuleDefinition.url,
    },
});
const homeModuleDefinition = createModuleDefinition('home');
registerApplication({
    name: homeModuleDefinition.name,
    app: () => System.import(homeModuleDefinition.name),
    activeWhen: homeModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: homeModuleDefinition.url,
    },
});
start();
