import { createStylesheet } from './create_stylesheet';
export const createModuleDefinition = (moduleName) => {
    const { hostname, protocol } = window.location;
    const name = `@bifrost/${moduleName}`;
    const root = `/modules/${moduleName}`;
    const activePath = `/${moduleName}`;
    let url = `${protocol}//${hostname}${root}/module.js`;
    const overridesElement = document.getElementById('import-map-overrides');
    if (overridesElement?.textContent) {
        const overrides = JSON.parse(overridesElement.textContent);
        const overrideUrl = overrides.imports[name];
        if (overrideUrl) {
            url = overrideUrl;
        }
    }
    createStylesheet(url);
    return { name, root, activePath, url };
};
